






















import {Component, Vue} from "vue-property-decorator";
import KisaMetin from "@/components/comps/tools/KisaMetin.vue";

@Component({
    components: {KisaMetin}
})
export default class DosyaDetayEvraklar extends Vue {

    evraklar=[
        { dosyaAdi:"Haciz Kaldırma Talebi 25/02/2020", onayTarihi:"25/02/2020", gonderen:"Ankara 33.İcra Dairesi",gonderenDosyaNo:"2020/14864",kategori:"GELEN",
            aciklama:"Vekil MUKTEDİR İLHAN,Vekil AYKUT YALÇINKAYA,Vekil HALİL CİN,ARACIKISIKURUM MEHMET ALİ ÇAKIROĞLU ,BORÇLU VE MÜFLİS YEŞİM YÜCEDAĞ,ALACAKLI VODAFONE T"},
        { dosyaAdi:"Dosya Alacağina Haciz Yazısı Kayıt 21/02/2020", onayTarihi:"21/02/2020", gonderen:"Ankara 33.İcra Dairesi",gonderenDosyaNo:"2019/6666",kategori:"GİDEN",
            aciklama:""},
        { dosyaAdi:"Haciz Kaldırma Talebi 25/02/2020", onayTarihi:"25/02/2020", gonderen:"Ankara 33.İcra Dairesi",gonderenDosyaNo:"2018/1125",kategori:"GELEN",
            aciklama:"HACİZ KALDIRILACAK"},
        { dosyaAdi:"Genel Haciz Talebi 06/02/2020", onayTarihi:"06/02/2020", gonderen:"Av. NİHAN ERDOĞAN",gonderenDosyaNo:"2018/442614",kategori:"DOSYA",
            aciklama:"Vekil NİHAN ERDOĞAN,BORÇLU VE MÜFLİS İSMAİL YÜCEDAĞ,ALACAKLI YEŞİM YÜCEDAĞ"}
]
    headers = [
        {text: "Dosya Adı", value: "dosyaAdi", sortable: true},
        {text: "Onay Tarihi", value: "onayTarihi", sortable: true},
        {text: "Gönderen", value: "gonderen", sortable: false},
        {text: "Gönderen Dosya No", value: "gonderenDosyaNo", sortable: false},
        {text: "Kategori", value: "kategori", sortable: false},
        {text: "Açıklama", value: "aciklama", sortable: false},
        {text: "İndir", value: "actions", sortable: false},

    ];

}
