





























































































import {Component, Vue} from "vue-property-decorator";

@Component({
    components: {}
})
export default class DosyaDetaySGK extends Vue {

    sskBilgileri={
        tcKimlikNo:"43858605188",
        adi:"İSMAİL",
        soyadi:"ŞAHİN",
        ilkSoyadi:" ",
        anneAdi:"hayriye",
        babaAdi:"HÜSEYİN",
        dogumYeri:"ÇUMRA",
        dogumTarihi:"23.07.1993",
        isYeriUnvani:"FGR ELEKTRİK İNŞAAT TAAHHÜT SANAYİ VE TİCARET LİMİTED ŞİRKETİ",
    }

    borclular= [
        {id: 1, adSoyad: "Nadir Çiftçi", tcKimlikNo: "50132432018",sgkBilgileri:this.sskBilgileri},
        {id: 2, adSoyad: "Ozan Pırasa", tcKimlikNo: "11111111110",sgkBilgileri:this.sskBilgileri},
        {id: 3, adSoyad: "Ali Akman", tcKimlikNo: "11111111110",sgkBilgileri:this.sskBilgileri},
        {id: 4, adSoyad: "Burak Kapacak", tcKimlikNo: "11111111110",sgkBilgileri:this.sskBilgileri},
    ]
    seciliBorclu=this.borclular[0];
    headers = [
        {text: "Ad Soyad", value: "adSoyad", sortable: true},
        {text: "TC Kimlik No", value: "tcKimlikNo", sortable: false},

    ];

}
