

































import {Component, Mixins} from "vue-property-decorator";

import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";

@Component({
    components: {}
})
export default class EvrakEkleForm extends Mixins(ObjectInputMixin) {
    evrakTurleri = ["Aciz Belgesi Talebi", "Adres Araştırması Talebi", "Borca İtiraz Talebi", "Dosya Hesabı Talebi", "Satış Talebi", "89/1 Haciz İhbarnamesi Gönderilmesi Talebi"]

}
