












import {Component, Mixins} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
    components: {}
})
export default class MahkemePicker extends Mixins(SimpleInputMixin) {
    mahkemeler = ["İzmir 1. Aile Mahkemesi","İzmir 2. Aile Mahkemesi","İzmir 3. Aile Mahkemesi","İzmir 4. Aile Mahkemesi","İzmir 5. Aile Mahkemesi"]
}
