














import {Component, Mixins} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
    components: {}
})
export default class EvrakGondermeKosuluPicker extends Mixins(SimpleInputMixin) {
    kosullar = [
        { id:1,kosulAciklama:"Koşul Yok"},
        { id:2,kosulAciklama:"Düşecek Olan Dosyalar"},
        { id:3,kosulAciklama:"SGK Aktif Olan Borçlular"},
        { id:4,kosulAciklama:"EGM, Aracı Olan Borçlular"},
        ]
}
