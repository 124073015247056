












import {Component, Mixins} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
    components: {}
})
export default class YargiBirimleriPicker extends Mixins(SimpleInputMixin) {
    birimler = ["Ağır Ceza Mahkemesi","Aile Mahkemesi","Asliye Hukuk Mahkemesi","Asliye Ticaret Mahkemesi","İdare Hukuk Mahkemesi","İş Mahkemesi"]
}
