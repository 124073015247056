





















































































































import {Component, Vue} from "vue-property-decorator";
import DosyaDetayTemel from "@/components/eicrapro/detay-comps/DosyaDetayTemel.vue";
import DosyaDetayAdres from "@/components/eicrapro/detay-comps/DosyaDetayAdres.vue";
import DosyaDetaySGK from "@/components/eicrapro/detay-comps/DosyaDetaySGK.vue";
import DosyaDetaySafahat from "@/components/eicrapro/detay-comps/DosyaDetaySafahat.vue";
import DosyaDetayEvraklar from "@/components/eicrapro/detay-comps/DosyaDetayEvraklar.vue";
import DosyaDetayTahsilatReddiyat from "@/components/eicrapro/detay-comps/DosyaDetayTahsilatReddiyat.vue";

@Component({
    components: {
        DosyaDetayTahsilatReddiyat,
        DosyaDetayEvraklar, DosyaDetaySafahat, DosyaDetaySGK, DosyaDetayAdres, DosyaDetayTemel}
})
export default class DosyaDetay extends Vue {
    mounted(){
        console.log("fuckd up")
    }
    tabNames = ["Dosya Detayı", "Adres", "SGK Haciz", "SGK", "EGM", "Safahat", "Evraklar", "Takbis", "Tahsilat/Reddiyat"]
    tabs: any = null;
    search: string = "";
    kapat(){
        console.log("emit etmem lazım")
        this.$emit('close',false)
    }
}
