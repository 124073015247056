





































import {Component, Vue} from "vue-property-decorator";

@Component({
    components: {}
})
export default class DosyaDetayTemel extends Vue {
    Detay={
        turu:"İlamsız Takip",
        esasTutar:15.2,
        vekaletUcreti:144.0,
        BSMV:23,
        ozelIletisimVergisi:5,
        bakiyeBorc:478.91,

        yolu:"Genel Haciz Yoluyla Takip",
        alacakMiktari:255,
        toplamFaiz:23,
        KKDF:0,
        yekunHesap:478.2,
        dosyaDurumu:"Açık",

        sekli:"7 İlamsız Takiplerde Ödeme Emri - Eski No: 49",
        tahsilHarci:13,
        masraf:200,
        KDV:18,
        yatanPara:0
    }
    borclular= [{id: 1, adSoyad: "Nadir Çiftçi", tcKimlikNo: "11111111110"}, {
    id: 2,
    adSoyad: "Süleyman Soba",
    tcKimlikNo: "11111111110",
}]
    headers = [
        {text: "Ad Soyad", value: "adSoyad", sortable: true},
        {text: "TC Kimlik No", value: "tcKimlikNo", sortable: false},

    ];

}
