












import {Component, Mixins} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
    components: {}
})
export default class YargiTuruPicker extends Mixins(SimpleInputMixin) {
    turler = ["Ceza","Hukuk","İcra","İdari Yargı","Satış Memurluğu","Arabuluculuk"]
}
