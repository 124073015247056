














import {Component, Mixins} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
    components: {}
})
export default class EvrakTuruPicker extends Mixins(SimpleInputMixin) {
    turler = [
        { id:1,Tur:"Aciz Belgesi Talebi"},
        { id:2,Tur:"Adres Araştırması Talebi aktif olan borçlular"},
        { id:3,Tur:"Bakiye Borç Muhtırası Tebliği Talebi"},
        { id:4,Tur:"Bankalarda Hesap Açılması İçin Talep Yazı"},
        { id:4,Tur:"Borca İtiraz Talebi"},
    ]
}
