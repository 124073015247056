


















































































import {Component, Prop, Vue} from "vue-property-decorator";
import UyapTakipAcXmlForm from "@/components/eicrapro/UyapTakipAcXmlForm.vue";
import UyapTakipAcTakipListe from "@/components/eicrapro/UyapTakipAcTakipListe.vue";
import GenelTakipFiltreForm from "@/components/eicrapro/GenelTakipFiltreForm.vue";
import GenelTakipListe from "@/components/eicrapro/GenelTakipListe.vue";
import SorgulanacakAlanlar from "@/components/eicrapro/SorgulanacakAlanlar.vue";
import MasrafOdeForm from "@/components/eicrapro/MasrafOdeForm.vue";
import HazirEvrakGonder from "@/components/eicrapro/HazirEvrakGonder.vue";

@Component({
    components: {
        HazirEvrakGonder,
        MasrafOdeForm,
        SorgulanacakAlanlar,
        GenelTakipListe, GenelTakipFiltreForm, UyapTakipAcTakipListe, UyapTakipAcXmlForm}
})
export default class Takipler extends Vue {
    step: number = 1;
    selectedFormforstep3=0;
    selectedFormTitle="";
    goStepThree(toWhere:any){
        this.step=3;
        this.selectedFormforstep3=toWhere.id;
        this.selectedFormTitle = toWhere.title;
    }
}
