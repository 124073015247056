














import {Component, Mixins} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
    components: {}
})
export default class EvrakGondermeKosuluPicker extends Mixins(SimpleInputMixin) {
    dilekceler = [
        { id:1,dilekce:"Gayrimenkul Haczi Talebi"},
        { id:2,dilekce:"Gayrimenkul Haczi Talebi 2"},
        { id:3,dilekce:"89/1 Alacak Haczi - Vakıflar Bankası"},
        { id:4,dilekce:"89/1 Alacak Haczi - Ziraat Bankası"},
        { id:5,dilekce:"Posta Çeki Sorgulama Talebi"},
        { id:6,dilekce:"Araç Haczi Talebi"},
        { id:7,dilekce:"Maaş Haczi Talebi"},
    ]
}
