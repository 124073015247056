



























import {Component, Vue} from "vue-property-decorator";

@Component({
    components: {}
})
export default class DosyaDetaySafahat extends Vue {

    safahatlar = [
        {
            isiYapanBirim: "Ankara 16. İcra Dairesi",
            personel: "İCRA KÂTİBİ REYHAN İSMAİLOĞULLARI",
            tarih: "02/03/2018 15:04",
            islemTuru: "Gelen Evrak Kaydedilmesi",
            aciklama: "Ödeme İcra Emri",
            islemDurumu: "Onaylı"
        },
        {
            isiYapanBirim: "Ankara 16. İcra Dairesi",
            personel: " AVUKAT PORTAL İŞLEMİ",
            tarih: "02/03/2018 15:04",
            islemTuru: "Yeni Taraf Eklendi",
            aciklama: "Zirve Bilgisayar Tic. ve San. Ltd. Şti. - Av.NİHAN ERDOĞAN(Alacaklı Vekili) Den 35,90 TL Başvurma Harcı 1,40 TL Peşin Harç 5,20 TL Vekalet Suret Harcı Alindi.",
            islemDurumu: "Onaylı"
        },
        {
            isiYapanBirim: "Ankara 16. İcra Dairesi",
            personel: "İCRA KÂTİBİ REYHAN İSMAİLOĞULLARI",
            tarih: "02/03/2018 15:04",
            islemTuru: "Yeni Taraf Eklendi",
            aciklama: "Ödeme İcra Emri",
            islemDurumu: "Onaylı"
        },
        {
            isiYapanBirim: "Ankara 16. İcra Dairesi",
            personel: " AVUKAT PORTAL İŞLEMİ",
            tarih: "02/03/2018 15:04",
            islemTuru: "Gelen Evrak Kaydedilmesi",
            aciklama: "Ödeme İcra Emri",
            islemDurumu: "Onaylı"
        },
    ]

}
