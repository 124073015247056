







































































import {Component, Vue} from "vue-property-decorator";

@Component({
    components: {}
})
export default class DosyaDetayAdres extends Vue {

    mernisKimlikRes={tcNo:"50132432018",adi:"Nadir",soyadi:"Çiftçi",babadi:"Kenan",anaAdi:"Ayça",dogumYeri:"Bursa",dogumTarihi:"2020-01-02",nufusaKayitliIl:"Edirne",
        nufusaKayitliIlce:"Selimiye",mahalleKoy:"Cami", verilisNedeni:"Degistirme",verilisTarihi:"2020-02-02",verildigiIl:"Ankara",cuzdanSeriNo:"A10Z31109",ciltNo :21,
    aileSiraNo:84,siraNo:190,dini:"Katolik"
    }
    mernisAdresRes={
        adresTipi:"İl İlçe", beyanTarihi:"2010-08-10",tasinmaTarihi:"2010-08-11",tescilTarihi:"2010-08-10",mahalle:"Fener Mahallesi",caddeSokak:"Tekelioğlu caddesi",
        disKapiNo:54, icKapiNo:12, il:"Antalya", ilce: "Muratpasa"
    }
    borclular= [
        {id: 1, adSoyad: "Nadir Çiftçi", tcKimlikNo: "50132432018",mernisKimlik:this.mernisKimlikRes,mernisAdres:this.mernisAdresRes},
        {id: 2, adSoyad: "Ozan Pırasa", tcKimlikNo: "11111111110",mernisKimlik:this.mernisKimlikRes,mernisAdres:this.mernisAdresRes},
        {id: 3, adSoyad: "Ali Akman", tcKimlikNo: "11111111110",mernisKimlik:this.mernisKimlikRes,mernisAdres:this.mernisAdresRes},
        {id: 4, adSoyad: "Burak Kapacak", tcKimlikNo: "11111111110",mernisKimlik:this.mernisKimlikRes,mernisAdres:this.mernisAdresRes},
    ]
    seciliBorclu=this.borclular[0];
    headers = [
        {text: "Ad Soyad", value: "adSoyad", sortable: true},
        {text: "TC Kimlik No", value: "tcKimlikNo", sortable: false},

    ];

}
