













































































import {Component, Vue} from "vue-property-decorator";
import Tutar from "@/components/inputs/Tutar.vue";
import ItemArrayManager from "@/components/common-inputs/ItemArrayManager.vue";
import DeleteButton from "@/components/common-inputs/DeleteButton.vue";

@Component({
    components: {DeleteButton, ItemArrayManager, Tutar}
})
export default class MasrafOdeForm extends Vue {
    tarafSecim = 0;
    giderTuru = 0;
    adet = 0;
    secim = {id: 0, turKod: 0, tur: "", tutarmi: true, varsayilanTutar: 0, varsayilanOran: 0};
    tutar = 0;
    toplamTutar = 0;
    eklenenharclarvemasraflar: any = [];
    harclarvemasraflar = [
        {id: 1, turKod: 0, tur: "Başvuru Harcı", tutarmi: true, varsayilanTutar: 500, varsayilanOran: 0},
        {id: 2, turKod: 0, tur: "Cezaevi Harcı", tutarmi: false, varsayilanTutar: 0, varsayilanOran: 0.5},
        {
            id: 3,
            turKod: 0,
            tur: "Haciz Teslim ve Satış Harcı",
            tutarmi: true,
            varsayilanTutar: 0,
            varsayilanOran: 0
        },
        {id: 4, turKod: 1, tur: "Bilirkişi Tahsilatı", tutarmi: true, varsayilanTutar: 0, varsayilanOran: 0},
        {id: 5, turKod: 1, tur: "Borç Tahsilatı", tutarmi: true, varsayilanTutar: 2500, varsayilanOran: 0},
        {
            id: 6,
            turKod: 1,
            tur: "Satış Bedeli Tahsilatı",
            tutarmi: false,
            varsayilanTutar: 0,
            varsayilanOran: 11.12
        },
        {id: 7, turKod: 0, tur: "Vekalet Sureti Harcı", varsayilanTutar: 0, varsayilanOran: 4.55},
        {id: 8, turKod: 2, tur: "Vekalet Pulu", tutarmi: true, varsayilanTutar: 300, varsayilanOran: 0},
    ]

    getTurler(): any {
        return this.harclarvemasraflar.filter(ttt => ttt.turKod === this.giderTuru);
    }

    varsayilanAyarla() {
        this.tutar = 0;
        if (this.secim.tutarmi) {
            this.tutar = this.secim.varsayilanTutar;
        }
    }

    hesapla() {
        this.toplamTutar = this.tutar * this.adet;
        if (!this.secim.tutarmi) {
            this.toplamTutar = this.toplamTutar * this.secim.varsayilanOran;
        }
    }

    Ekle() {
        let item: any = {
            tur: this.secim.tur,
            toplamTutar: this.toplamTutar
        }
        this.eklenenharclarvemasraflar.push(item)
    }

    headers = [
        {text: "Tür", value: "tur"},
        {text: "Toplam Tutar", value: "toplamTutar"},
        {text: "Sil", value: "actions"},
    ];

}

