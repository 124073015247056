







































import {Component, Vue} from "vue-property-decorator";
import KisaMetin from "@/components/comps/tools/KisaMetin.vue";

@Component({
    components: {KisaMetin}
})
export default class DosyaDetayTahsilatReddiyat extends Vue {

    harclar=[
        { dosyaNo:"2016/5570", dosyaTur:"İcra Dosyası", birimAdi:"Ankara 3.İcra Dairesi",harcMasrafAdi:"Başvurma Harcı",tahsilatTarihi:"08/03/2016",
            kisi:"İlker Canikligil",makbuzNo:"11235",miktar:"29.2"},
        { dosyaNo:"2016/5570", dosyaTur:"İcra Dosyası", birimAdi:"Ankara 3.İcra Dairesi",harcMasrafAdi:"Peşin Harç",tahsilatTarihi:"08/03/2016",
            kisi:"İlker Canikligil",makbuzNo:"11236",miktar:"31.59"},
        { dosyaNo:"2016/5570", dosyaTur:"İcra Dosyası", birimAdi:"Ankara 3.İcra Dairesi",harcMasrafAdi:"Vekalet Suret Harcı",tahsilatTarihi:"08/03/2016",
            kisi:"İlker Canikligil",makbuzNo:"11237",miktar:"4.3"},
        { dosyaNo:"2016/5570", dosyaTur:"İcra Dosyası", birimAdi:"Ankara 3.İcra Dairesi",harcMasrafAdi:"Posta Masrafı",tahsilatTarihi:"18/09/2017",
            kisi:"İlker Canikligil",makbuzNo:"11238",miktar:"8"},
    ]
    tahsilatlar=[
        { dosyaNo:"2016/5570", dosyaTur:"İcra Dosyası", birimAdi:"Ankara 3.İcra Dairesi",tahsilatTuru:"Masraf Avansı Tahsilatı",tahsilatTarihi:"08/03/2016",
            odeyenKisi:"İlker Canikligil",makbuzNo:"1231",tahsilatTutari:"60",kalan:"13.09"},
        { dosyaNo:"2016/5570", dosyaTur:"İcra Dosyası", birimAdi:"Ankara 3.İcra Dairesi",tahsilatTuru:"Masraf Avansı Tahsilatı",tahsilatTarihi:"08/03/2016",
            odeyenKisi:"İlker Canikligil",makbuzNo:"1232",tahsilatTutari:"13.09",kalan:"0"}
    ]
    headersHarc = [
        {text: "Harç/Masraf Adı", value: "harcMasrafAdi", sortable: false},
        {text: "Tahsilat Tarihi", value: "tahsilatTarihi", sortable: false},
        {text: "Kişi", value: "kisi", sortable: false},
        {text: "Makbuz No", value: "makbuzNo", sortable: false},
        {text: "Miktar", value: "miktar", sortable: false},
    ];
    headersTahsilat = [
        {text: "Tahsilat Türü", value: "tahsilatTuru", sortable: false},
        {text: "Tahsilat Tarihi", value: "tahsilatTarihi", sortable: false},
        {text: "Ödeyen Kişi", value: "odeyenKisi", sortable: false},
        {text: "Makbuz No", value: "makbuzNo", sortable: false},
        {text: "Tutar", value: "tahsilatTutari", sortable: false},
        {text: "Kalan", value: "kalan", sortable: false},
    ];

}
