








































import {Component, Vue} from "vue-property-decorator";

@Component({
    components: {}
})
export default class SorgulanacakAlanlar extends Vue {
    selectable = ["SGK SSK", "SSK Aktif", "SGK Kamu", "SGK BAĞKUR", "SGK İşyeri", "SGK Haciz","SGK SSK", "SSK Aktif"]
    selected:any = [];
    allSelected = false;
    selectAll(){
        console.log("back to black",this.allSelected)
        if (this.allSelected) {
            this.selected= [];
            this.selectable.forEach((item:string)=> {
                this.selected.push(item);
            })
        }
        else{
            this.selected = [];
        }
    }
}

