

































import {Component, Vue} from "vue-property-decorator";
import EvrakGondermeKosuluPicker from "@/components/pickers/EvrakGondermeKosuluPicker.vue";
import EvrakTuruPicker from "@/components/pickers/EvrakTuruPicker.vue";
import GonderilecekDilcekcePicker from "@/components/pickers/GonderilecekDilcekcePicker.vue";

@Component({
    components: {GonderilecekDilcekcePicker, EvrakTuruPicker, EvrakGondermeKosuluPicker}
})
export default class HazirEvrakGonder extends Vue {
    secilenEvrakGondermeKosul: number = 1;
    secilenEvrakTuru: number = 1;
    secilenDilekce: number = 1;
    aciklama: string = "";
    ucuncuSahis: boolean = false;
}

