var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',[_c('v-dialog',{attrs:{"persistent":"","width":"1200"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('dosya-detay',{on:{"close":function($event){_vm.dialog=false}}})],1),(_vm.Dosyalar!=null)?_c('v-data-table',{staticClass:"table table-striped",attrs:{"headers":_vm.headers,"items":_vm.Dosyalar,"search":_vm.search,"show-select":"","hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"item.alacaklilar",fn:function(ref){
var item = ref.item;
return [(item.alacaklilar.length > 4)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('ul',_vm._g({},on),[_vm._l((3),function(index){return _c('li',[_vm._v(" "+_vm._s(item.alacaklilar[index-1].adSoyad)+" ")])}),_c('li',[_vm._v("...")])],2)]}}],null,true)},[_c('ul',_vm._l((item.alacaklilar),function(alacakli){return _c('li',[_vm._v(_vm._s(alacakli.adSoyad))])}),0)])]:_c('ul',_vm._l((item.alacaklilar),function(alacakli){return _c('li',[_vm._v(_vm._s(alacakli.adSoyad))])}),0)]}},{key:"item.borclular",fn:function(ref){
var item = ref.item;
return [(item.borclular.length > 4)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('ul',_vm._g({},on),[_vm._l((3),function(index){return _c('li',[_vm._v(" "+_vm._s(item.borclular[index-1].adSoyad)+" ")])}),_c('li',[_vm._v("...")])],2)]}}],null,true)},[_c('ul',_vm._l((item.borclular),function(borclu){return _c('li',[_vm._v(_vm._s(borclu.adSoyad))])}),0)])]:_c('ul',_vm._l((item.borclular),function(borclu){return _c('li',[_vm._v(_vm._s(borclu.adSoyad))])}),0)]}},{key:"item.details",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"medium":"","color":"light-blue darken-4","dark":"","icon":""},on:{"click":function($event){_vm.dialog=true}}},[_c('v-icon',[_vm._v("mdi-eye-outline")])],1),_c('form-dialog-button',{attrs:{"medium":"","width":"1200","color":"light-blue darken-4","title":"Taraf Bilgileri","icon":"mdi-account-multiple-outline"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formData = ref.formData;
var onInput = ref.onInput;
return [_c('uyap-takip-ac-detay',{on:{"input":onInput},model:{value:(_vm.Dosyalar[_vm.Dosyalar.indexOf(item)]),callback:function ($$v) {_vm.$set(_vm.Dosyalar, _vm.Dosyalar.indexOf(item), $$v)},expression:"Dosyalar[Dosyalar.indexOf(item)]"}})]}}],null,true)}),_c('form-dialog-button',{attrs:{"medium":"","width":"1200","color":"light-blue darken-4","title":"Alacak Kalemleri","icon":"mdi-tag-multiple-outline"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formData = ref.formData;
var onInput = ref.onInput;
return [_c('uyap-takip-ac-alacak-kalemleri',{attrs:{"items":item.alacaklar}})]}}],null,true)})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('form-dialog-button',{attrs:{"tooltip":"Evrak Ekle","medium":"","width":"600","color":"light-blue darken-4","title":"Evrak Ekle","icon":_vm.iconGetir(item)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formData = ref.formData;
var onInput = ref.onInput;
return [_c('evrak-ekle-form')]}}],null,true)}),_c('form-dialog-button',{attrs:{"tooltip":"Masraf/Harç Öde","medium":"","width":"600","color":"light-blue darken-4","title":"Masraf/Harç Öde","icon":"mdi-credit-card-plus-outline"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formData = ref.formData;
var onInput = ref.onInput;
return [_c('evrak-ekle-form')]}}],null,true)})]}},{key:"item.durum",fn:function(ref){
var item = ref.item;
return [(item.durum===0)?_c('v-progress-circular',{attrs:{"width":2,"size":25,"color":"primary","indeterminate":""}}):_vm._e(),(item.durum===1)?_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-close ")]):_vm._e(),(item.durum===2)?_c('v-icon',{attrs:{"color":"orange"}},[_vm._v(" mdi-alert-circle-outline ")]):_vm._e(),(item.durum===3)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check ")]):_vm._e()]}},{key:"item.aciklama",fn:function(ref){
var item = ref.item;
return [(item.durum===0)?_c('span',[_vm._v(" -")]):_vm._e(),(item.durum===1)?_c('span',[_c('kisa-metin',{attrs:{"length":15},model:{value:(item.durumAciklama),callback:function ($$v) {_vm.$set(item, "durumAciklama", $$v)},expression:"item.durumAciklama"}})],1):_vm._e(),(item.durum===2)?_c('span',[_c('kisa-metin',{attrs:{"length":15},model:{value:(item.durumAciklama),callback:function ($$v) {_vm.$set(item, "durumAciklama", $$v)},expression:"item.durumAciklama"}})],1):_vm._e(),(item.durum===3)?_c('span',[_vm._v("-")]):_vm._e()]}},{key:"footer",fn:function(){return [_c('v-col',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"white--text ma-10",attrs:{"small":"","width":"140","elevation":"5","height":"40","color":"green darken-3"}},[_vm._v("Evrak Gönder "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-send ")])],1),_c('v-btn',{staticClass:"white--text ma-10",attrs:{"small":"","width":"140","elevation":"5","height":"40","color":"green darken-3"},on:{"click":_vm.HazirEvrakGonder}},[_vm._v("Hazır Evrak "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-file-export-outline ")])],1),_c('v-btn',{staticClass:"white--text ma-10",attrs:{"small":"","width":"140","elevation":"5","height":"40","color":"green darken-3"},on:{"click":_vm.SorguBaslat}},[_vm._v("Sorgula "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-database-search-outline ")])],1),_c('v-btn',{staticClass:"white--text ma-10",attrs:{"small":"","width":"140","elevation":"5","height":"40","color":"green darken-3"},on:{"click":_vm.OdemeYap}},[_vm._v("Ödeme Yap "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-credit-card-check ")])],1)],1)]},proxy:true}],null,false,3101088324)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }