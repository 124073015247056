








































































































import {Component, Mixins} from "vue-property-decorator";
import IlPicker from "@/components/pickers/IlPicker.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import KotaKullanimSekliPicker from "@/components/pickers/KotaKullanimSekliPicker.vue";
import IbanKullanimTercihiPicker from "@/components/pickers/IbanKullanimTercihiPicker.vue";
import AdliyePicker from "@/components/pickers/AdliyePicker.vue";
import YargiTuruPicker from "@/components/pickers/YargiTuruPicker.vue";
import YargiBirimleriPicker from "@/components/pickers/YargiBirimleriPicker.vue";
import MahkemePicker from "@/components/pickers/MahkemePicker.vue";
import FormDialogButton from "@/components/common-inputs/FormDialogButton.vue";
import TcKimlikNumarasi from "@/components/inputs/TcKimlikNumarasi.vue";
import Dates from "@/components/inputs/Dates.vue";
import VergiNumarasi from "@/components/inputs/VergiNumarasi.vue";

@Component({
    components: {
        VergiNumarasi,
        Dates,
        TcKimlikNumarasi,
        FormDialogButton,
        MahkemePicker,
        YargiBirimleriPicker,
        YargiTuruPicker, AdliyePicker, IbanKullanimTercihiPicker, KotaKullanimSekliPicker, IlPicker
    }
})
export default class GenelTakipFiltreForm extends Mixins(ObjectInputMixin) {
    secim = 0;
    kisiKurumSecim=0;
    acilisTarihSecim = 0;
    kapanisTarihSecim = 0;
    esasNoVar = false;
    aramaVerileri = {
        acilisBaslangic: "2020-01-01",
        acilisBitis: "2020-01-01",
        kapanisBaslangic: "2020-01-01",
        kapanisBitis: "2020-01-01",
        esasNoYil: "2020",
        esasNo1: "0",
        esasNo2: "1",
        ad: "",
        soyad: "",
        tc: "",
        kurumad: "",
        mersisNo: "",
        vergiNo: "",
    }
    TarihiSecimler = [{id: 0, icerik: "Aralık Yok"}, {id: 1, icerik: "Son 1 Ay"}, {
        id: 2,
        icerik: "Son 2 Hafta"
    }, {id: 3, icerik: "Son 1 Hafta"}
        , {id: 4, icerik: "Son 1 Gün"}, {id: 5, icerik: "Aralık Seç"},]
}
